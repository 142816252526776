<template>
  <div class="devices playlist playlists">
    <div class="sub-wrap">
      <div class="container">
        <form @submit="update_playlist" class="form" method="post">
          <div class="header-wrap cf">
            <div class="header-left">
              <h2>{{ playlist.name }}</h2>
            </div>
            <div class="header-right">
              <p>
                <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round" />
              </p>
            </div>
          </div>

          <devices-nav></devices-nav>

          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("playlists.title") }}</h3>
            </header>
            <div class="content-body">
              <div class="form-wrap" style="margin-bottom: 0">
                <label for="name">{{ $t("global.name") }}</label>
                <input v-model="playlist.name" class="form-input" type="name" name="name" id="name" />
              </div>

              <div class="row row-gutter-20" style="margin-top: 30px">
                <div class="col-12">
                  <div class="content-wrap">
                    <header class="content-header">
                      <h3>{{ $t("playlists.showPlaylists") }}</h3>
                    </header>
                    <div class="content-body">
                      <div v-if="loading">
                        <div class="loading-wrap">
                          <div class="loading-spinner"></div>
                        </div>
                      </div>

                      <div v-else>
                        <div v-if="playlist_ads.length == 0">
                          <p class="alert alert-info" style="margin: 0; text-align: center">{{ $t("playlists.empty") }}</p>
                        </div>
                        <div v-else>
                          <div class="list-group">
                            <div class="list-group-item" v-for="(ad, index) in playlist_ads" :key="index">
                              <div class="row row-gutter-20 handle">
                                <div class="col-8">
                                  <div v-if="ad.thumbnail">
                                    <img :src="ad.thumbnail" />
                                  </div>
                                  <div v-else>
                                    <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                                  </div>
                                </div>
                                <div class="col-16">
                                  <h3>{{ ad.name }}</h3>
                                  <p>{{ $t("global.duration") }}: {{ ad.duration }} {{ $t("global.seconds") }}</p>
                                  <p>
                                    <a @click="remove_from_playlist_ads(index)">{{ $t("global.delete") }}</a>
                                  </p>
                                  <p>
                                    <a v-if="index < playlist_ads.length - 1" @click="move_down_playlist_ads(index)" class="arrow-move">{{
                                      $t("playlists.moveDown")
                                    }}</a>
                                    <a v-if="index > 0" @click="move_up_playlist_ads(index)" class="arrow-move">{{ $t("playlists.moveUp") }}</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="content-wrap">
                    <header class="content-header">
                      <h3>{{ $t("playlists.availableAds") }}</h3>
                      <p class="header-action">
                        <router-link :to="'/playlists/' + this.$route.params.uuid + '/advertisments/new'" class="button button-ci button-round button-small">
                          <img src="../../assets/icons/plus.svg" />
                          <span>{{ $t("global.add") }}</span>
                        </router-link>
                      </p>
                    </header>
                    <div class="content-body">
                      <div v-if="loading">
                        <div class="loading-wrap">
                          <div class="loading-spinner"></div>
                        </div>
                      </div>

                      <div v-else>
                        <div v-if="available_ads.length == 0">
                          <p class="alert alert-info" style="margin: 0; text-align: center">{{ $t("playlists.emptyAds") }}</p>
                        </div>
                        <div v-else>
                          <div class="list-group">
                            <div class="list-group-item" v-for="ad in available_ads" :key="ad.uuid">
                              <div class="row row-gutter-20 handle">
                                <div class="col-8">
                                  <div v-if="ad.thumbnail">
                                    <img :src="ad.thumbnail" />
                                  </div>
                                  <div v-else>
                                    <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                                  </div>
                                </div>
                                <div class="col-16">
                                  <h3>
                                    {{ ad.name }}
                                    <small v-if="ad.asset_file"
                                      ><a :href="ad.asset_file" target="_blank">
                                        <span class="material-icons" style="color: #444; font-size: 16px; margin-left: 5px; vertical-align: sub"
                                          >open_in_new</span
                                        >
                                      </a></small
                                    >
                                  </h3>
                                  <p style="margin: 5px 0; font-size: 14px">
                                    {{ $t("global.duration") }}: {{ ad.duration }} {{ $t("global.seconds") }} | {{ $t("global.format") }}:
                                    {{ ad.orientation }}
                                  </p>
                                  <p v-if="ad.status == 'creating'" class="ad-preparing alert-details">
                                    <small>{{ $t("playlists.notAvailableAd") }}</small>
                                  </p>
                                  <p v-if="ad.status == 'available'">
                                    <a @click="add_to_playlist_ads(ad)" class="button button-ci button-small button-round">{{
                                      $t("playlists.addToPlaylist")
                                    }}</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p style="margin-bottom: 0; text-align: right">
                <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round button-100" />
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";
import DevicesNav from "../../components/DevicesNav.vue";

export default {
  name: "playlist",
  components: { DevicesNav },
  data() {
    return {
      loading: true,
      playlist: {},
      playlist_ads: [],
      available_ads: [],
    };
  },
  methods: {
    get_playlist() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/playlists/" + this.$route.params.uuid, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.playlist = response.data.playlist;
        });
    },
    get_playlist_advertisments(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/playlists/" + this.$route.params.uuid + "/advertisments?page=" + page, {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          response.data.advertisments.forEach((ad) => {
            this.playlist_ads.push(ad);
          });
          if (response.data.meta.pagination.links.next) {
            this.get_playlist_advertisments(response.data.meta.pagination.links.next.split("=")[1]);
          } else {
            this.loading = false;
          }
        });
    },
    get_advertisments(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/advertisments?page=" + page, {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          response.data.advertisments.forEach((ad) => {
            this.available_ads.push(ad);
          });
          if (response.data.meta.pagination.links.next) {
            this.get_advertisments(response.data.meta.pagination.links.next.split("=")[1]);
          } else {
            this.loading = false;
          }
        });
    },
    update_playlist: function (e) {
      e.preventDefault();

      var advertisments = [];
      var position = 0;
      var ad = "";
      this.playlist_ads.forEach(function (item) {
        ad = item;
        ad.position = position;
        advertisments.push(ad);
        position = position + 1;
      });

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/playlists/" + this.$route.params.uuid,
          {
            name: this.playlist.name,
            advertisments: advertisments,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.$router.push("/playlists");
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    add_to_playlist_ads(ad) {
      this.playlist_ads.push(ad);
    },
    remove_from_playlist_ads(index) {
      this.playlist_ads.splice(index, 1);
    },
    move_down_playlist_ads(index) {
      let cutOut = this.playlist_ads.splice(index, 1)[0];
      this.playlist_ads.splice(index + 1, 0, cutOut);
    },
    move_up_playlist_ads(index) {
      let cutOut = this.playlist_ads.splice(index, 1)[0];
      this.playlist_ads.splice(index - 1, 0, cutOut);
    },
  },
  mounted() {
    this.get_playlist();
    this.get_playlist_advertisments(1);
    this.get_advertisments(1);
  },
};
</script>

<style lang="scss">
.list-group {
  @media (min-width: 768px) {
    max-height: 600px;
    overflow: scroll;
  }

  .list-group-item {
    border: 1px dotted #ccc;
    margin-bottom: 10px;
    padding: 14px;
    border-radius: 5px;
  }
}

.playlist-empty {
  width: 100%;
  background: #f9f9f9;
  text-align: center;
  padding: 20px;
  border: 2px dotted #ccc;
  color: #aaa;
}

@media only screen and (max-width: 650px) {
  .playlists {
    .col-16 {
      width: 100%;
    }

    .col-8 {
      width: 100%;
    }
  }
}

.arrow-move {
  display: inline-block;
  margin-right: 10px;
  padding: 10px 20px;
  background: #f1f1f1;
  border-radius: 5px;
}

.ad-preparing {
  border-radius: 3px;
  padding: 5px 10px;
  font-weight: 600;
}
</style>
